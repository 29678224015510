import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import PageHeader from "../components/Global/PageHeader";
import ProductPage from "../components/Products/ProductPage/ProductPage";

import sketchViewerBG from "./../images/SketchViewer/sketch-viewer-bg.png";
import Layout from "../components/Layout";

const SketchViewer = (props) => {
  const { t } = useTranslation();

  const data = {
    pageTitle: t("TR_SKETCHVIEWER_PAGE_TITLE"),
    content: {
      title: t("TR_SKETCHVIEWER_TITLE"),
      text: t("TR_SKETCHVIEWER_TEXT"),
    },
    button: {
      label: t("TR_SKETCHVIEWER_BUTTON"),
      destination: "https://sketches.cadt.com/",
      color: "cadt",
    },
  };

  return (
    <div className='bg-neutral-50 text-neutral-700'>
      <Layout>
        {data && (
          <div
            className='sm:bg-cover sm:bg-center bg-top bg-no-repeat bg-[length:800px_auto]'
            style={{
              backgroundImage: `url(${sketchViewerBG})`,
            }}
          >
            <PageHeader pageTitle={data.pageTitle} />
            <ProductPage
              content={data.content}
              photos={props.data.sketchviewer.nodes}
              button={data.button}
            />
          </div>
        )}
      </Layout>
    </div>
  );
};

export default SketchViewer;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    sketchviewer: allFile(
      filter: { relativePath: { regex: "/sketch-viewer-slide/i" } }
      sort: { fields: relativePath }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const title = JSON.parse(
    data.locales.edges[0].node.data
  ).TR_MENU_SKETCH_VIEWER;

  return (
    <>
      <title>{title} | CADT</title>
    </>
  );
};
